import axios from "axios";
import { SendError } from "./error-log-service";

/**
 * @description Posts a new Email Template
 * @returns {String} If API response message success it sets the email template
 */
export const PostEmailTemplate = async (data: any) => {
  return await axios
    .post(`EmailTemplate`, data)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      SendError(error);
      return error;
    });
};

/**
 * @description gets all Email Templates
 * @returns [] A collection of email templates
 */
export const GetAllEmailTemplates = async () => {
  return await axios
    .get(`EmailTemplate/list`)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      SendError(error);
      return error;
    });
};

/**
 * @description Updates email template
 * @returns {Number} API Response Code
 */
export const UpateEmailTemplate = async (data: any) => {
  return await axios
    .put(`EmailTemplate`, data)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      SendError(error);
      return error;
    });
};


/**
 * @description Sends email template
 * @returns {Number} API Response Code
 */
 export const sendEmail = async (data: any) => {
  return await axios.put(`EmailTemplate/send`, data).then(function (response: any) {
      return response;
    }).catch(function (error: any) {
      SendError(error);
      return error;
    });
};

export const GetTemplateCount = async () => {
  return await axios.get(`EmailTemplate/count`).then(function (response: any) {
      return response;
  }).catch(function (error: any) {
      return error.response?.data.message;
  });
}