import axios from "axios";
/**
* @description 
    Returns a list of orders
    starting_after is an object ID that defines your
    place in the list. For instance, if you make a list request and receive 100 objects,
    ending with obj_foo, your subsequent call can include starting_after=obj_foo
    in order to fetch the next page of the list.
*/
export const getOrders = async (offset: number, fetch: number, searchTerm: string, start: Date, end: Date, reportType: number = 0) => {
    let config = {};

    if (offset !== null && fetch !== null) {
        config = {
            params: {
                offset: offset,
                fetch: fetch,
                searchTerm: !searchTerm ? "" : searchTerm,
                start: start,
                end: end,
                reportType: reportType
            }
        }
    }

    return await axios.get(`placenta/order/full/`, config).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
        return error.response?.data.message;
    });
}


export const getOrderCount = async () => {
    return await axios.get(`order/count/`).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
        return error.response?.data.message;
    });
}

/**
   * @description Set order delivered
   * @returns OK Result if successful
 */
export const setOrderDelivered = async (request: any) => {
    return await axios.post(`order/delivered`, request).then(function (response: any) {
        return response;
    })
}
