/** @jsx jsx */
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { jsx, Text } from "theme-ui";
import PrimaryLayout from "../components/layout/primary/primary";
import AccountTopMenu from "../components/top-menus/account/account-top-menu";
import UserStats from "../components/DashBoardFunStats/users-stats/users-stats";
import {
  getJwtTokenIdentifier,
  validateJwtToken,
  getJwtTokenRoles,
} from "../services/auth-service";
import { GetUserCount, GetUser } from "../services/user-service";
import { GetTemplateCount } from "../services/email-service";

import Spinner from "react-bootstrap/Spinner";
import SEO from "../components/seo";
import { getOrderCount } from "../services/order-service";
import { FiDatabase, FiUsers } from "react-icons/fi";
import { AiOutlineMail, AiOutlineLineChart } from "react-icons/ai";

const AccountPage: React.FunctionComponent<{}> = ({ }: any) => {
  const [name, setName] = useState("");
  const userId = getJwtTokenIdentifier();
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);
  const [user, setUser] = useState();

  const [sendEmails, setSendEmails] = useState(false);
  const [viewOrders, setViewOrders] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [viewReports, setViewReports] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const templates = await GetTemplateCount();
      const total = await getOrderCount();
      const users = await GetUserCount();
      const user = await GetUser(userId);
     
      setName(`${user.data.person.firstName} ${user.data.person.surname}`);
      setTotalRecords(total.data);
      setUserCount(users.data);
      setUser(user.data);
      setTemplateCount(templates.data);

      const roles = getJwtTokenRoles();
      setSendEmails(roles.includes("FullAccess"));

      if (roles.includes("FullAccess")) {
        setSendEmails(true);
        setViewOrders(true);
        setViewUsers(true);
        setViewReports(true);
      }
      else {
        setSendEmails(roles.includes("SendEmails"));
        setViewOrders(roles.includes("ViewOrders"));
        setViewUsers(roles.includes("ViewUser"));
        setViewReports(roles.includes("ViewReports"));
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (!validateJwtToken()) return null;
  const isAdmin = getJwtTokenRoles().includes("Admin");

  return (
    <PrimaryLayout pathPrefix="account" topMenuChild={AccountTopMenu}>
      <SEO title="My Account" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      {!loading ? (
        <div sx={styles.container} className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12" sx={styles.header}>
              {name !== "" && <h1>Hello {name}</h1>}
              {name === "" && <h1>My Account</h1>}
              <hr className="bar-indent bar-mobile" />
            </div>

            {isAdmin && (
              <Text as="p">You are logged in as the system adminstrator.</Text>
            )}
          </div>

         
          <div className="row g-2" sx={styles.actionArea}>

          {viewOrders && (
            <div className="col-md-3 col-sm-12" sx={styles.section.tile}>
              <Link to="/account/orders" sx={styles.controls.link}>
                <div sx={styles.section}>
                  <FiDatabase  />
                  <p sx={styles.section.tile.header}>Orders</p>
                    <UserStats value={totalRecords} />
                </div>
              </Link>
            </div> 
            )}

            {sendEmails && (
              <div className="col-md-3 col-sm-12" sx={styles.section.tile}>
                <Link to="/account/email-templates" sx={styles.controls.link}>
                  <div sx={styles.section}>
                    <AiOutlineMail />
                    <p sx={styles.section.tile.header}>Templates</p>
                    <UserStats value={templateCount} />
                  </div>
                </Link>
              </div>
            )}

            {viewUsers && (
              <div className="col-md-3 col-sm-12" sx={styles.section.tile}>
                <Link to="/account/user-management" sx={styles.controls.link}>
                  <div sx={styles.section}>
                    <FiUsers />
                    <p sx={styles.section.tile.header}>Users</p>
                    <UserStats value={userCount} />
                  </div>
                </Link>
              </div>
            )}

            {viewReports && (
              <div className="col-md-3 col-sm-12" sx={styles.section.tile}>
                <Link to="/account/reports" sx={styles.controls.link}>
                  <div sx={styles.section}>
                    <AiOutlineLineChart />
                    <div className="center">
                      <p sx={styles.section.tile.header}>Reports</p>
                      <UserStats value={2} />
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        <span sx={styles.spinner}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </span>
      )}
    </PrimaryLayout>
  );
};

const styles = {
  header: {
    "@media only screen and (max-width: 500px)": {
      textAlign: "center",
    },
  },
  container: {
    marginTop: 40,
    paddingLeft: 0,
    paddingRight: 0,
    "@media only screen and (max-width: 500px)": {
      marginTop: 20,
    },
    maxWidth: "1390px!important",
  },
  actionArea: {
    marginTop: 10
  },
  section: {
    textAlign: 'center',
    background: "#FFF",
    height: '100%',
    borderRadius: "12px",
    border: "1.5px solId #e4e4e4",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxShadow: "0 0.125rem 0.25rem 0 #d8d8d9",
    svg: {
      fontSize: '82px',
      color: '#00d4c5'
    },
    "@media only screen and (max-width: 500px)": {
      padding: "40px",
    },
    "@media only screen and (min-width: 501px) and (max-width: 1035px)": {
      padding: "40px",
    },
    // textAlign: "center",
    "&:hover, &.active": {
      cursor: "pointer",
      boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
      transition:
        "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    },
    tile: {
      position: 'relative',
      header: {
        fontSize: '22px',
        fontWeight: 600
      }
    }
  },

  controls: {
    paddingTop: "1em",
    link: {
      display: "contents",
      textDecoration: "none",
    },
    content: {
      padding: "10px",
      "@media only screen and (max-width: 500px)": {
        width: "100%",
      },
      "@media only screen and (min-width: 501px) and (max-width: 900px)": {
        width: "100%",
      },
    },
    ".subscriptions": {
      "@media only screen and (min-width: 501px) and (max-width: 900px)": {
        width: "100%",
      },
    },
  },

  spinner: {
    marginTop: "40px",
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
  // users: { marginTop: "2.5em" },

  chart: {
    marginTop: "2em",
    marginBottom: ".2em",
    padding: "0.5em",
  },

  emailStats: { marginTop: "2em" },
};

export default AccountPage;
